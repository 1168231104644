import React, { useEffect } from "react";
import { Drawer } from "antd";
import { Link } from "gatsby";
import { PhoneOutlined, FacebookOutlined, EnvironmentOutlined } from "@ant-design/icons";
import logo from "../img/insuremart-logo-light.svg";

import "./styles/mobile-drawer.scss";

const MobileDrawer = ({ menuIsOpen, setMenuIsOpen }) => {
  // useEffect(() => {
  //   if (menuIsOpen) {
  //     document.body.style.overflowY = "hidden";
  //   } else {
  //     document.body.style.overflowY = "initial";
  //   }
  // }, [menuIsOpen]);

  return (
    <Drawer
      className="mobile-drawer"
      title={null}
      placement="left"
      onClose={() => setMenuIsOpen(false)}
      visible={menuIsOpen}
      width="80%"
      height="100%"
      closable={false}
    >
      <div className="footer has-text-white-ter pb-6">
        <div className="container content has-text-left">
          <img src={logo} alt="Kaldi" style={{ width: "240px" }} />
        </div>
        <div className="content has-text-centered has-text-white-ter">
          <div className="container has-text-white-ter">
            <div style={{ maxWidth: "100vw" }} className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list ml-0">
                    <li>
                      <Link to="/" className="navbar-item">
                        Home
                      </Link>
                    </li>
                    {/* <li>
                      <Link className="navbar-item" to="/about">
                        About
                      </Link>
                    </li> */}
                    <li>
                      <Link className="navbar-item" to="/faqs">
                        FAQs
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/contact">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-drawer__footer">
          <a
            className="header__top__phone"
            href="https://www.facebook.com/Healthnx-108154174393463"
            target="_blank"
          >
            <FacebookOutlined className="mr-2" /> Healthnx
          </a>
          <a className="header__top__phone" href="tel:1(833)649-0448" target="_self">
            <PhoneOutlined className="mr-2" />
            {process.env.INSURITA_SERVICE_LINE}
          </a>
          <span style={{ display: "flex" }} className="header__top__phone">
            <EnvironmentOutlined className="mt-2 mr-2" />
            1799 W Oakland Park Blvd,
            <br />
            Oakland Park, FL <br />
            33311
          </span>
        </div>
      </div>
    </Drawer>
  );
};

export default MobileDrawer;
