import React from "react";
import { Link } from "gatsby";
import logo from "../img/insuremart-logo.svg";
import { PhoneOutlined, FacebookOutlined } from "@ant-design/icons";

import "./styles/header.scss";
import "./styles/burger.scss";

const Header = ({ setMenuIsOpen }) => {
  return (
    <header
      id="header"
      className="header navbar is-transparent"
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="header__wrapper">
        <div className="header__top">
          <a className="header__top__phone" href="tel:1(833)649-0448" target="_self">
            <PhoneOutlined className="mr-2" />
            {process.env.INSURITA_SERVICE_LINE}
          </a>
          <span className="header__top__facebook">
            <FacebookOutlined
              onClick={() =>
                window.open("https://www.facebook.com/Healthnx-108154174393463", "_blank")
              }
              className="header__top__social"
            />
          </span>
        </div>
      </div>
      <div className="container">
        <div className="header__row">
          <Link to="/" title="Logo">
            <img src={logo} alt="Kaldi" className="header__logo" />
          </Link>
          {/* Hamburger menu */}
          <div className="header__row__right">
            <div onClick={() => window.open("tel:(833)649-0448", "_self")} className="header__call">
              <PhoneOutlined className="header__call__icon" />
            </div>
            <div
              onClick={() => setMenuIsOpen(true)}
              id="nav-icon"
              // className={state.menuIsOpen ? 'open' : null}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div id="navMenu" className="header__row__right--desktop">
            <div className="navbar-end has-text-centered">
              <Link className="navbar-item" to="/">
                Home
              </Link>
              {/* <Link className="navbar-item" to="/products">
                About Us
              </Link> */}
              <Link className="navbar-item" to="/faqs">
                FAQs
              </Link>
              <Link className="navbar-item" to="/contact">
                Contact
              </Link>
              <div
                onClick={() => window.open("tel:(833)649-0448", "_self")}
                className="header__call mt-1"
              >
                <PhoneOutlined className="header__call__icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
