import React from "react";
import { Link } from "gatsby";

import logo from "../img/insuremart-logo-light.svg";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-text-white-ter pb-6">
        <div>
          <div className="container content has-text-left">
            <img src={logo} alt="Kaldi" style={{ width: "240px" }} />
          </div>
          <div className="content has-text-centered has-text-white-ter">
            <div className="container has-text-white-ter">
              <div style={{ maxWidth: "100vw" }} className="columns">
                <div className="column is-4">
                  <section className="menu">
                    <ul className="menu-list ml-0">
                      <li>
                        <Link to="/" className="navbar-item">
                          Home
                        </Link>
                      </li>
                      {/* <li>
                      <Link className="navbar-item" to="/about">
                        About
                      </Link>
                    </li> */}
                      <li>
                        <Link className="navbar-item" to="/faqs">
                          FAQs
                        </Link>
                      </li>
                      <li>
                        <Link className="navbar-item" to="/contact">
                          Contact
                        </Link>
                      </li>
                      {/* <li>
                      <a
                        className="navbar-item"
                        href="/admin/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Admin
                      </a>
                    </li> */}
                    </ul>
                  </section>
                </div>

                <div className="has-text-left ml-4 mr-6 mt-4">
                  <p className="is-size-7">
                    Copyright © 2020 Healthnx. All Rights Reserved. healthnx.com is a privately
                    owned and operated independent advertiser, not a health insurance carrier or
                    government website. Any logos, copyrights, or trademarks are the property of
                    their respective owners. Insurance products, companies, and regulations vary by
                    state, and the insurance companies and/or products listed or displayed on this
                    page may not be available in all instances
                  </p>
                  <p className="is-size-7">Vectors by icons8.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
