import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Header from "./Header";
import useSiteMetadata from "./SiteMetadata";
import MobileDrawer from "./MobileDrawer";
import { withPrefix } from "gatsby";

import "./styles/all.sass";
import "./styles/global.scss";

const TemplateWrapper = ({ children, pathname = "" }) => {
  const { title, description } = useSiteMetadata();
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          rel="stylesheet"
          type="text/css"
          charSet="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content={`${withPrefix("/")}img/og-image.jpg`} />
      </Helmet>
      <Header setMenuIsOpen={setMenuIsOpen} />
      <div className="page-container">{children}</div>
      <MobileDrawer menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
      {pathname !== "/your-health-plan" && <Footer />}
    </div>
  );
};

export default TemplateWrapper;
